/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { CanActivateInterests } from 'app/modules/admin/interests/interests.guards';
import { CanViewMarketRegions } from 'app/modules/admin/market-regions/marketRegion.guards';
import {
    CanViewBrandOrders,
    CanViewMainOrders,
} from 'app/modules/admin/order/order.guards';
import { CanActivatePartner } from 'app/modules/admin/partner/partner.guards';
import { CanActivateProduct } from 'app/modules/admin/product/product.guards';
import { CanActivateUser } from 'app/modules/admin/user/user.guards';
import { CanActivateVod } from 'app/modules/admin/vod-manager/vod-manager.guards';

const navigation = new Array<FuseNavigationItem>(
    {
        id: 'user',
        title: 'User',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/user',
        hidden: (): boolean => !AppInjector.get(CanActivateUser).canActivate(),
    },
    {
        id: 'vod',
        title: 'Content',
        type: 'basic',
        icon: 'swan:video-actions-video-lib',
        link: '/vod',
        hidden: (): boolean => !AppInjector.get(CanActivateVod).canActivate(),
    },
    {
        id: 'interest',
        title: 'Interest',
        type: 'basic',
        icon: 'swan:misc-tag',
        link: '/interest',
        hidden: (): boolean =>
            !AppInjector.get(CanActivateInterests).canActivate(),
    },
    {
        id: 'product',
        title: 'Product',
        type: 'basic',
        icon: 'swan:misc-shopping',
        link: '/product',
        hidden: (): boolean =>
            !AppInjector.get(CanActivateProduct).canActivate(),
    },
    {
        id: 'partner',
        title: 'Partner',
        type: 'basic',
        icon: 'mat_outline:add_business',
        classes: {
            icon: 'icon-size-6',
        },
        link: '/partner',
        hidden: (): boolean =>
            !AppInjector.get(CanActivatePartner).canActivate(),
    },
    {
        id: 'orders',
        title: 'Orders',
        type: 'basic',
        icon: 'heroicons_solid:clipboard-check',
        classes: {
            icon: 'icon-size-6',
        },
        link: '/orders',
        hidden: (): boolean =>
            !AppInjector.get(CanViewMainOrders).canActivate(),
    },
    {
        id: 'brandorders',
        title: 'Orders',
        type: 'basic',
        icon: 'heroicons_outline:shopping-cart',
        classes: {
            icon: 'icon-size-6',
        },
        link: '/orders/brand',
        hidden: (): boolean =>
            !AppInjector.get(CanViewBrandOrders).canActivate(),
    },
    {
        id: 'marketRegions',
        title: 'Market Regions',
        type: 'basic',
        icon: 'heroicons_outline:globe-alt',
        classes: {
            icon: 'icon-size-6',
        },
        link: '/market-regions',
        hidden: (): boolean =>
            !AppInjector.get(CanViewMarketRegions).canActivate(),
    }
    /*
   {
       id     : 'creators-path',
       title  : 'Creators Path',
       type   : 'basic',
       icon   : 'mat_outline:add_business',
       classes: {
           icon: 'icon-size-6',
       },
       link   : '/creators-path',
       hidden : (): boolean => !AppInjector.get(CanActivateProduct).canActivate(),
   },*/
);

export const defaultNavigation: FuseNavigationItem[] = navigation;
export const compactNavigation: FuseNavigationItem[] = navigation;
export const futuristicNavigation: FuseNavigationItem[] = navigation;
export const horizontalNavigation: FuseNavigationItem[] = navigation;
